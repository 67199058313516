const SpeakerSVG = (props) => {
    return(
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 42.664 469.333 384.005" width="30" height="30" viewBox="0 42.664 469.333 384.005" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation" aria-hidden="true" aria-labelledby="svgcid--3vqeda-rz4b5i"><title id="svgcid--3vqeda-rz4b5i"></title>
            <g fill={props.fill ? props.fill : "white"}>
                <path d="M206.885 43.544c-3.875-1.698-8.448-.896-11.542 2.042L85.49 149.336H10.667A10.66 10.66 0 0 0 0 160.002v149.333a10.66 10.66 0 0 0 10.667 10.667H85.49l109.854 103.75a10.631 10.631 0 0 0 7.323 2.917c1.427 0 2.865-.281 4.219-.875a10.644 10.644 0 0 0 6.448-9.792V53.336a10.647 10.647 0 0 0-6.449-9.792z"></path>
                <path d="M262.452 174.884l-15.199 15.199c-3.505 3.505-4.44 9.168-1.513 13.168A53.056 53.056 0 0 1 256 234.669a53.05 53.05 0 0 1-10.26 31.417c-2.927 4-1.992 9.664 1.513 13.169l15.199 15.198c4.655 4.656 12.259 3.939 16.28-1.276 12.495-16.208 19.935-36.505 19.935-58.508 0-22.004-7.44-42.301-19.935-58.509-4.021-5.215-11.625-5.932-16.28-1.276z"></path>
                <path d="M338.423 114.575c-4.01-4.53-11.151-4.512-15.428-.234l-15.092 15.092c-4.043 4.042-4.005 10.395-.27 14.721 20.991 24.307 33.701 55.954 33.701 90.514 0 34.56-12.71 66.207-33.701 90.516-3.736 4.326-3.772 10.677.27 14.719l15.092 15.092c4.277 4.279 11.418 4.296 15.43-.233C366.771 322.755 384 280.686 384 234.669c0-46.018-17.229-88.087-45.577-120.094z"></path>
                <path d="M398.764 54.138c-4.082-4.43-11.151-4.417-15.409-.158l-15.112 15.112c-4.053 4.053-4.033 10.451-.167 14.685 36.376 39.84 58.59 92.822 58.59 150.892s-22.214 111.051-58.59 150.892c-3.865 4.234-3.887 10.632.167 14.685l15.112 15.111c4.259 4.26 11.328 4.272 15.41-.158 43.79-47.529 70.568-110.961 70.568-180.53 0-69.57-26.778-133.003-70.569-180.531z"></path>
            </g>
        </svg>
    )
}

export default SpeakerSVG;
