
import KoolioBg from '../../assets/images/koolio-bg.png';

const Banner = () => {
    return(
        <div className=''>
            <img src={KoolioBg} alt='koolio.ai background' className='w-responsive'  />
        </div>
    )
}

export default Banner;
