
const HexaSVG = (props) => {

    return (
        <div className="w-90 flex flex-wraps">
            <svg className="flex justify-around" width="260" height="260">
                <polygon points="140, 6,240, 56,240, 150, 140, 200,46, 150,46, 56" 
                stroke="#8A2BE2" 
                strokeWidth="2" 
                fill={props.fill}/>
                <foreignObject x="75" y="100" width="140" height="150">
                    <div xmlns="http://www.w3.org/1999/xhtml" style={{'color': 'grey', 'textAlign': 'center'}}>{props.desc}</div>
                </foreignObject>
                <circle cx="50" cy="50" r="50" fill="#8A2BE2" />
                <text x="23" y="67" fill="white" fontSize="3em" >{props.id}</text>
                <foreignObject x="127" y="40" width="140" height="150">
                    {props.children}
                </foreignObject>
            </svg>
        </div>
    )
}

export default HexaSVG;
