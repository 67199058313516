import FadeInLeft from "../../components/UI/FadeInLeftBox";
import FadeInRightBox from "../../components/UI/FadeInRightBox";
import KoolioMac from '../../assets/images/koolio-site-mac (1).png';

const DescriptionContainer = () => {
    return (
        <div>
            <DesktopView/>
            <MobileView />
        </div>
    )
}

const DesktopView = () => (
    <div className="hidden sm:flex  flex-row h-full mt-16 w-full">
        <div className="flex w-1/2 justify-end items-center">
            <div className="w-11/12">
                <FadeInLeft xOffset={-44} delay={0} duration={1} delayOrder={2}>
                    <div className="header">
                        <h3 className="text-primary text-2xl lg:text-5xl font-bold">AUDIO CONTENT CREATION</h3> 
                        <h3 className="text-white text-3xl md:text-lg lg:text-5xl block">FOR EVERYONE.</h3>
                        <h4 className="text-gray text-xl lg:text-xl">ALL OF THE POWER. NONE OF THE HASSLE.</h4>
                    </div>
                </FadeInLeft>
                <FadeInLeft xOffset={-44} delay={0.5} duration={1} delayOrder={2}>
                    <div>
                        <p className="text-gray mt-8 text-xs md:text-xs lg:text-lg">koolio.ai lets you take a concept to completed content in a matter of minutes. 
                            We help you make quality content painlessly. Whether it’s transcribing audio, 
                            collaborating with others, auto-selecting sound effects or music based on context to enhance your content,
                            or performing audio operations and manipulations easily, koolio.ai provides a simple, web-based, 
                            easy to use and intuitive interface for you to focus on your creativity.
                        </p>
                    </div>
                </FadeInLeft>
            </div>
        </div>
        <div className="w-1/2 flex overflow-hidden">
            <FadeInRightBox xOffset={104} delay={0} duration={1} delayOrder={2}>
                <div className="w-full h-full">
                    <img src={KoolioMac} className="ml-[15%] w-[1900px]" alt='koolio.ai workspace' />
                </div>
            </FadeInRightBox>
        </div>
    </div>
)

const MobileView = () => (
    <div className="flex flex-wrap flex-col mt-24 sm:hidden">
        <div className="flex flex-row w-full h-2/3">
            <div className="flex w-7/12 justify-center items-center">
                <div className="block w-full pl-6">
                    <FadeInLeft xOffset={-44} delay={0} duration={1} delayOrder={2}>
                        <div className="header">
                            <h1 className="text-primary text-2xl font-bold">AUDIO CONTENT CREATION
                            <span className="text-white font-medium block">FOR EVERYONE.</span></h1>
                        </div>
                    </FadeInLeft>
                </div>
            </div>
            <div className="w-5/12  overflow-hidden">
                <FadeInRightBox xOffset={104} delay={0} duration={1} delayOrder={2}>
                    <div className="relative block w-full h-full ml-5">
                        <img src={KoolioMac}
                            alt='koolio.ai background' layout="responsive" width={350} height={350} />
                    </div>
                </FadeInRightBox>
            </div>
        </div>
        <div>
            <h4 className="text-gray text-lg md:text-lg lg:text-xl text-left w-5/6 px-6">ALL OF THE POWER. NONE OF THE HASSLE.</h4>
                <FadeInLeft xOffset={-44} delay={0.5} duration={1} delayOrder={2}>
                    <div className="text-left w-5/6 px-6">
                        <p className="text-gray mt-8 text-xl md:text-md lg:text-lg">koolio.ai lets you take a concept to completed content in a matter of minutes. 
                            We help you make quality content painlessly. Whether it’s transcribing audio, 
                            collaborating with others, auto-selecting sound effects or music based on context to enhance your content,
                            or performing audio operations and manipulations easily, koolio.ai provides a simple, web-based, 
                            easy to use and intuitive interface for you to focus on your creativity.
                        </p>
                    </div>
                </FadeInLeft>
        </div>
    </div>
)

export default DescriptionContainer;
