const ExportSVG = (props) => {
    return (
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 0 512 512" width="30" height="30" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation" aria-hidden="true" aria-labelledby="svgcid-tftxu-dxtp7m"><title id="svgcid-tftxu-dxtp7m"></title>
            <g fill={props.fill ? props.fill : "white"}>
                <path d="M480 288v112c0 44.183-35.817 80-80 80H112c-44.183 0-80-35.817-80-80V112c0-44.183 35.817-80 80-80h96V0h-96C50.144 0 0 50.144 0 112v288c0 61.856 50.144 112 112 112h288c61.856 0 112-50.144 112-112V288h-32z"></path>
                <path d="M176 416h32V288c0-125.76 107.52-128 112-128h105.44l-84.64 84.64 22.56 22.56 112-112c6.204-6.241 6.204-16.319 0-22.56l-112-112-22.72 22.72 84.8 84.64H320c-1.44 0-144 1.76-144 160v128z"></path>
            </g>
        </svg>
    )
}

export default ExportSVG;
