const TowerSpeakerSVG = (props) => {
    return (
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 15.323 512 481.354" xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 15.323 512 481.354" height="30" data-type="shape" role="presentation" aria-hidden="true" aria-labelledby="svgcid-c0tncv-43psnm"><title id="svgcid-c0tncv-43psnm"></title>
            <g fill={props.fill ? props.fill : "white"}>
                <path d="M255.366 377.324c16.524 0 29.969-13.458 29.969-30s-13.444-30-29.969-30-29.968 13.458-29.968 30 13.444 30 29.968 30z"></path>
                <path d="M255.366 105.323c-16.524 0-29.968 13.458-29.968 30s13.444 30 29.968 30 29.969-13.458 29.969-30-13.444-30-29.969-30z"></path>
                <path d="M375.756 15.323H135.904v211h239.852zm-120.39 180c-33.066 0-59.968-26.916-59.968-60s26.902-60 59.968-60c33.067 0 59.969 26.916 59.969 60s-26.901 60-59.969 60z"></path>
                <path d="M135.904 496.677h30v-29.353h179.852v29.353h30V256.324H135.904zm119.462-209.353c33.067 0 59.969 26.916 59.969 60s-26.901 60-59.969 60c-33.066 0-59.968-26.916-59.968-60s26.902-60 59.968-60z"></path>
                <path d="M460.542 138.068l-17.875 24.094C467.296 180.433 482 209.649 482 240.314c0 30.677-14.704 59.9-39.333 78.172l17.875 24.094C492.764 318.675 512 280.445 512 240.314c0-40.119-19.236-78.341-51.458-102.246z"></path>
                <path d="M424.835 186.267L406.96 210.36c9.585 7.112 15.083 18.03 15.083 29.954 0 11.937-5.498 22.861-15.083 29.974l17.875 24.093c17.037-12.64 27.208-32.852 27.208-54.066 0-21.203-10.171-41.408-27.208-54.048z"></path>
                <path d="M30 240.333c0-30.677 14.704-59.9 39.333-78.172l-17.874-24.094C19.237 161.972 0 200.202 0 240.333c0 40.119 19.237 78.342 51.458 102.246l17.874-24.094C44.704 300.214 30 270.999 30 240.333z"></path>
                <path d="M105.041 210.36l-17.876-24.093c-17.036 12.64-27.207 32.852-27.207 54.066 0 21.202 10.171 41.406 27.207 54.046l17.876-24.093c-9.585-7.112-15.083-18.029-15.083-29.954 0-11.936 5.497-22.86 15.083-29.972z"></path>
            </g>
        </svg>
    )
}

export default TowerSpeakerSVG;
