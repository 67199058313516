import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'
import YouTube from "react-youtube";

const VideoCarousel = ({changeHandler, infoArray}) => {
    const [autoLoop, setAutoLoop] = useState(true);
    const [isPaused, setIsPaused] = useState(true);
    const [videoElement, setVideoElement] = useState(null);

    const opts = {
        height: '90%',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
    };

    const _onReady = (e) => {
        setVideoElement(e);
    }

    const handleOnClick = (e) => {
        setVideoElement(e)
        if(e.data === 1) {
            setAutoLoop(false)
        } else {
            setAutoLoop(true)
        }
        setIsPaused(!isPaused)
    }

    const handleOnChange = (e) => {
        videoElement.target.stopVideo() 
        changeHandler(e)
    }

    return(
        <div className="overflow-hidden">
            <Carousel 
            showThumbs={false} 
            stopOnHover
            autoPlay={autoLoop} 
            infiniteLoop 
            interval={5000}
            showStatus={false} 
            emulateTouch={true} 
            swipeScrollTolerance={1}
            onChange={(e) => handleOnChange(e)}
            onClickItem={(e) => handleOnClick(e)} 
            >
                {infoArray.map((info, i) => (
                    <div key={i} className="flex justify-center items-center" >
                        {/* <iframe 
                        className="w-[360px] aspect-video sm:w-[580px] md:w-100" 
                        src={info.url} 
                        onPlay={handleOnClick}
                        title="YouTube video player" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen></iframe> */}
                        <YouTube  
                        className="aspect-video w-full h-full flex"
                        videoId={info.videoId}
                        id={info.videoId} 
                        opts={opts} 
                        onStateChange={handleOnClick}
                        onReady={_onReady}></YouTube>
                    </div>
                ))}
            </Carousel>
        </div>
        );
    }

export default VideoCarousel;
