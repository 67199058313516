import React, { useMemo, useRef, useState, useEffect } from "react";
import { motion, useInView } from "framer-motion";

const FadeInLeftBox = ({
    children,
    xOffset = -24, // y initial possition
    duration = 0.4,
    delay = 0.25,
    easing = [0.42, 0, 0.58, 1], // [number, number, number, number] | "linear" | "easeIn" |
    //  "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" |
    // "backInOut" | "anticipate" | EasingFunction;
    delayOrder, // order of appearance
    ...rest
}) => {
    const ref = useRef(null);
    const inView = useInView(ref);

    const [animateComp, setAnimateComp] = useState(false);
    const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        if(renderCount < 1 && inView) {
            setAnimateComp(true);
            setRenderCount(renderCount+1);
        }
    }, [renderCount, inView]);

    const transition = useMemo(
        () => ({
            duration,
            delay,
            ease: easing
        }),
        [delay, easing]
    );

    const variants = {
        hidden: { x: xOffset, opacity: 0, transition },
        show: {
            y: 0,
            x: 0,
            opacity: 1,
            transition
        }
    };

    return (
        <motion.div
            initial="hidden"
            animate={animateComp ? "show" : "hidden"}
            exit="hidden"
            variants={variants}
            {...rest}
            ref={ref}
        >
            {children} {inView}
        </motion.div>
    );
};

export default FadeInLeftBox;
