import React from 'react';
import PlanCard from './planCard';
import "./plans.css";

const PlansShow = ({plans,redirectSubscription}) => {
    const freePlanFirst =()=>{
        if(plans[0].id!=="f3ce00b8-d019-3dfe-8446-c64a843894e2") {
            plans.push(plans.shift(plans[0]))
            freePlanFirst()
        }
    }
    freePlanFirst()
    return (
        <div className="pricing-modal half-modals-actions text-center mt-20">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-16 w-4/6 md:w-4/5 lg:w-2/3 mx-auto">
                {
                    plans.map((plan, i)=>
                    {
                        if(plan.id !== "0010798c-a761-4bd0-a108-61c9b884e4db"){
                            return <PlanCard key={i} plan={plan} plans={plans} redirectSubscription={redirectSubscription}></PlanCard>
                        }
                    })
                    
                }
                </div>
            </div>
    );
};

export default PlansShow;