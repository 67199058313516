import React, { useState } from 'react';
import { motion } from 'framer-motion';

import Banner from '../../components/Banner';
import MouseAnime from '../../components/MouseAnime';
import FadeInUpBox from '../../components/UI/FadeInUpBox';
import KoolioLogo from '../../assets/images/koolio-logo.png';

const IntroContainer = () => {

    const [isRedirect, setIsRedirect] = useState(false);

    const scrollHandler = () => {
        const elem = document.getElementsByClassName('descriptionContainer');
        elem[0].scrollIntoView({ behavior: 'smooth' });
    }

    const redirect = () => {
        if(!isRedirect) {
            setIsRedirect(true)
            fetch('https://api.koolio.ai/web-redirect-count', {
                method: 'POST',
                body: JSON.stringify({
                    os: window.navigator.appVersion,
                    timezone: new Date().toString(),
                    created_at:  new Date().toISOString()
                })
            })
            .then(() => {
                window.location.href = 'https://app.koolio.ai/'
            })
        }
    }

    return (
        <div>
            <Banner />
            <div className='flex w-100 justify-center'>
                <div className='flex justify-center items-center w-7/12 sm:3/12 sm:mt-4'>
                    <img src={KoolioLogo}
                        alt='koolio.ai' width={323} height={104} />
                </div>
            </div>
            <FadeInUpBox yOffset={44} delay={0} duration={2} delayOrder={2}>
                <div className='flex mt-12 sm:mt-3 justify-center text-center flex-wrap flex-col sm:flex-row space-y-2 sm:space-y-0'>
                    <p className='text-3xl md:text-lg lg:text-5xl'>THE FUTURE OF <span className='text-primary font-bold text-3xl sm:flex-row md:text-xl lg:text-5xl space-y-2 sm:space-y-0'>AUDIO CONTENT CREATION.</span>
                    {/*<span className='text-primary font-bold text-3xl sm:flex-row md:text-xl lg:text-5xl space-y-2 sm:space-y-0'>AUDIO&nbsp;</span><span className='text-primary  font-bold text-3xl sm:flex-row md:text-xl lg:text-5xl space-y-2 sm:space-y-0'>CONTENT&nbsp;</span><span className='text-primary font-bold text-3xl sm:flex-row md:text-xl lg:text-5xl space-y-2 sm:space-y-0'>CREATION.</span>*/}
                    </p>
                </div>
            </FadeInUpBox>
            <FadeInUpBox yOffset={44} delay={1} duration={1} delayOrder={2}>
                <div className='early-access flex justify-center text-center mt-16 flex-wrap flex-col sm:flex-row sm:mt-4'>
                    {/*<p className='text-gray text-xl md:text-lg lg:text-xl'>WANT TO BE AN EARLY ADOPTER?&ensp;</p>*/}
                    <p className='text-gray text-xl md:text-lg lg:text-xl'>&ensp;ENTER YOUR&ensp;</p>
                    <div onClick={redirect} className='z-10'><p className='text-primary cursor-pointer text-lg underline md:text-lg lg:text-xl'>KOOLIOSPACE</p></div>
                </div>
            </FadeInUpBox>
            <div className='early-access mt-14 sm:mt-3'>
                <div className='flex items-center justify-center'>
                    <span className='flex flex-col items-center justify-center cursor-pointer z-50' onClick={scrollHandler}>
                        <div className='w-8 h-14 rounded-3xl border-2 mt-2 flex justify-start items-center flex-col'>
                            <motion.div animate={{ y: 20 }} transition={{ delay: 1.5, duration: 1, repeat: Infinity}} className='w-0 h-2 border mt-4'></motion.div>
                        </div>
                        <MouseAnime />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default IntroContainer;
