import React, { useEffect, useState } from 'react';
import "./plans.css";
import useWindowDimensions from '../../hooks/useWindowDimensions';

const PlanCard = ({plan, plans, redirectSubscription}) => {
    const { width } = useWindowDimensions();
    const [heightFix, setHeightFix] = useState(true)
    const planDetails = plan.details.split(";")
    const [currentPrice, setcurrentPrice] = useState(plan.price)
    let yearlyPlan
    if(plan.id === "c5b84d39-adbd-3f7c-ac5c-cd8c205ee5ff"){
        yearlyPlan = plans.find(yp => yp.id === "0010798c-a761-4bd0-a108-61c9b884e4db" )
    }
    
    // Step 1: Set up state to store the input value
    const [promoCode, setPromoCode] = useState('');

    // Step 2: Handle input change
    const handleInputChange = (e) => {
        setPromoCode(e.target.value);
    };

    const [discountedPrice, setdiscountedPrice] = useState(false)
    // Step 3: Handle button click and call the parent's callback function
    const handleApplyClick = () => {
        setdiscountedPrice(!discountedPrice)
    };

    useEffect(() => {
        fetch(`https://api.koolio.ai/get-discounted-price?promo_code=` + promoCode)
        .then(response => response.json())
        .then(data => {
            if (plan.name === 'Monthly Pro Subscription' && discountedPrice) {
                let newPrice = data.plans[5].discounted_price
                setcurrentPrice(newPrice)
            }
        })
    }, [discountedPrice])

    useEffect(()=>{
        if(document.getElementById("f3ce00b8-d019-3dfe-8446-c64a843894e2-ul")){
            document.getElementById("f3ce00b8-d019-3dfe-8446-c64a843894e2-ul").style.height = document.getElementById("c5b84d39-adbd-3f7c-ac5c-cd8c205ee5ff-ul").offsetHeight+"px"
        }
    },[width,heightFix])
    return (
        <div className="card mb-2 w-100">
            <div className="card-header bg-primary">
                <h4 className="my-0 text-lg md:text-lg lg:text-2xl">{plan.id ==="f3ce00b8-d019-3dfe-8446-c64a843894e2"?plan.name : "Pro Subscription"}</h4>
             </div>
            <div className="card-body-cus h-full" id={`${plan.name}`}>
                <ul id={`${plan.id}-ul`} className="list-unstyled  pl-3 font-small text-gray" >
                    {
                        planDetails.map((p, i)=>{
                            if(p.length){
                                return <li key={i} className='py-2'>{p}</li>
                            }
                        })
                    }
                </ul>
                
                {
                    plan.price === 0 &&
                    <button type="button" onClick={(e)=>redirectSubscription(e, null)} className="w-full half-modals-action-button">Free </button> 
                }
                {
                    plan.id === "c5b84d39-adbd-3f7c-ac5c-cd8c205ee5ff" &&
                    <div>
                        <div className="flex rounded-md shadow-sm" id="promocode-area">
                            <input
                                type="text"
                                placeholder="Enter Promo Code"
                                value={promoCode}
                                onChange={handleInputChange}
                                id="hs-trailing-button-add-on" 
                                name="promocode-input" 
                                className="w-full half-modals-action-input"
                            />
                            <button 
                                onClick={handleApplyClick} 
                                id="apply-button" 
                                className="w-40 half-modals-action-button">
                                    Apply
                            </button>
                        </div>
                        <p id="trial-month-tagline">Experience More, Pay Less! <br/> Enjoy 2 Months FREE of Pro Features!</p>
                    </div>
                }
                {
                    plan.price === 20 &&
                        <button type="button" onClick={(e)=>redirectSubscription(e, plan.id, promoCode)} className="w-full half-modals-action-button">{`$${currentPrice} / month`}</button>
                }
                {
                    plan.id === "c5b84d39-adbd-3f7c-ac5c-cd8c205ee5ff" &&
                    <button type="button" onClick={(e)=>redirectSubscription(e, yearlyPlan.id, promoCode)} className="w-full half-modals-action-button">{`$${yearlyPlan.price} / year`}</button>
                }
                {
                    plan.id === "c5b84d39-adbd-3f7c-ac5c-cd8c205ee5ff" && 
                    <p className='pb-2' id="yearly-save">Save 16% on yearly subscription</p>
                }

            </div>
        </div>
    );
};

export default PlanCard;