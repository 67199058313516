import { useState } from "react";
import VideoCarousel from "../../components/UI/VideoCarousel";

const VideoContainer = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const VideoArray = [
        {
            'head': "How to use koolio.ai",
            'body': ["Upload an audio file", "Record a new audio", "Cut, copy, paste and insert audio clips", "Multi select transcription", "Change speakers of Audio segments", "Insert another recording or file into the project", "Apply fades"],
            'url': 'https://www.youtube.com/embed/2mSg-6UJdWQ',
            'videoId': '2mSg-6UJdWQ'
        },
        {
            'head': "Direct Recording, Simple Editing and SFX",
            'body': [ "Global timeline", "Adding SFX and Music", "Resizing SFX and Music", "Adding Speaker", "Renaming speaker", "Sub volumes", "Audio Panning", "Muting Speakers and Soloing a Speaker", "Reviewing annotations"],
            'url': 'https://www.youtube.com/embed/wMrDqoKPXKg',
            'videoId': 'wMrDqoKPXKg'
        },
        {
            'head': "SFX, Speakers, annotations and sub volumes",
            'body': ["Viewing History", "Important messages", "Sharing and Collaborating with a Friend", "Downloading the project with AI enhanced Audio", "Publishing to audio content hosting sites"],
            'url': 'https://www.youtube.com/embed/ZP-Q2KJh-m0',
            'videoId': 'ZP-Q2KJh-m0'
        },
        {
            'head': "History, Exports, Publishing and Collaborations",
            'body': ["Cutting out filler words", "Selecting annotations from transcript", "Downloading the entire transcript"],
            'url': 'https://www.youtube.com/embed/tSruHBp9wzo',
            'videoId': 'tSruHBp9wzo'
        }
    ]

    const carouselOnchangeHandler = (e) => {
        setActiveIndex(e)
    }

    return(
        <div className="sm:flex flex-col sm:flex-row h-full mt-16 w-full">
            <div className="flex w-100 sm:w-3/5 justify-end">
                <VideoCarousel changeHandler={carouselOnchangeHandler} infoArray={VideoArray} />
            </div>
            <div className="w-100 sm:w-2/5 px-10 sm:pr-20">
                <h1 className="text-2xl font-medium text-secondary">{VideoArray[activeIndex].head}</h1>
                <div>
                    <ul className="list-disc pl-3 font-small text-gray">
                        {VideoArray[activeIndex].body.map((ele, i) => <li className="py-2" key={i}>{ele}</li>)}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default VideoContainer;
