
const OfferCard = (props) => {
    return (
        <div className="w-8/12 h-52 dark:bg-realBlack border border-gray flex flex-col justify-center flex-wrap transition ease-in delay-50 hover:-translate-y-1 hover:scale-110 hover:duration-300 sm:w-3/12">
            <div className="header w-full h-2/4 flex justify-evenly">
                <div className="w-36 h-100 flex self-center justify-end">
                    <div className="w-12 h-14 sm:18 md:h-14 ">
                        <img src={props.imgSrc}
                                alt='koolio.ai' />
                    </div>
                </div>
                <div className="w-4"></div>
                <div className="w-96 self-center">
                        <p className="text-md sm:text-md text-primary">{props.header}</p>
                </div>
            </div>
            <div className="body w-full h-2/4 text-center text-gray p-4 text-sm lg:text-lg flex flex-wrap"><div>{props.body}</div></div>
        </div>
    )
}

export default OfferCard;