const WaveSVG = (props) => {
    return (
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 1.896 232.914 229.121" width="30" height="30" viewBox="0 1.896 232.914 229.121" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation" aria-hidden="true" aria-labelledby="svgcid-gdvi0f-ezoyns"><title id="svgcid-gdvi0f-ezoyns"></title>
            <g fill={props.fill ? props.fill : "white"}>
                <path d="M9 64.099a9 9 0 0 0-9 9v86.716c0 4.971 4.029 9 9 9s9-4.029 9-9V73.099a9 9 0 0 0-9-9z"></path>
                <path d="M51.983 38.092a9 9 0 0 0-9 9v138.73c0 4.971 4.029 9 9 9s9-4.029 9-9V47.092a9 9 0 0 0-9-9z"></path>
                <path d="M94.966 1.896a9 9 0 0 0-9 9v211.121c0 4.971 4.029 9 9 9s9-4.029 9-9V10.896a9 9 0 0 0-9-9z"></path>
                <path d="M137.948 54.361a9 9 0 0 0-9 9v106.193c0 4.971 4.029 9 9 9s9-4.029 9-9V63.361a9 9 0 0 0-9-9z"></path>
                <path d="M180.931 64.099a9 9 0 0 0-9 9v86.716c0 4.971 4.029 9 9 9s9-4.029 9-9V73.099a9 9 0 0 0-9-9z"></path>
                <path d="M223.914 92.919a9 9 0 0 0-9 9v29.077c0 4.971 4.029 9 9 9s9-4.029 9-9v-29.077a9 9 0 0 0-9-9z"></path>
            </g>
        </svg>
    )
}

export default WaveSVG;
