import React, { useState, useEffect } from "react";
import "./index.css";

const DotScroller = () => {

    const [offset, setOffset] = useState(0);
    const [containers] = useState(['headContainer', 'descriptionContainer', 'offersContainer', 'featuresContainer', 'subscriptionPlansContainer', 'earlyAccessContainer']);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        const contmap = [];
        contmap.push({
            'name': containers[0],
            'offsetTop': document.getElementsByClassName(containers[0])[0].offsetTop,
            'offsetBottom': document.getElementsByClassName(containers[0])[0].offsetTop + document.getElementsByClassName(containers[0])[0].offsetHeight,
            'height': document.getElementsByClassName(containers[0])[0].offsetHeight
        })
        for(let container = 1; container < containers.length; container++) {
            contmap.push({
                'name': containers[container],
                'offsetTop': document.getElementsByClassName(containers[container - 1])[0].offsetTop + document.getElementsByClassName(containers[container - 1])[0].offsetHeight,
                'offsetBottom': document.getElementsByClassName(containers[container])[0].offsetTop + document.getElementsByClassName(containers[container])[0].offsetHeight,
                'height': document.getElementsByClassName(containers[container])[0].offsetHeight
            })
        }
        contmap.forEach(container => {
            const element = document.getElementsByClassName(container.name+"-pointer")[0];
            if(offset >= container.offsetTop && offset <= container.offsetBottom) {
                if(element)
                    element.classList.add("activeContainer")
            } else {
                if(element)
                element.classList.contains("activeContainer") && element.classList.remove("activeContainer")
            }
        })
    }, [offset, containers])

    const scrollHandler = (container) => {
        const element = document.getElementsByClassName(container)
        element[0].scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className='fixed mt-4 -ml-8 w-full h-full flex justify-end items-center'>
            <div className='relative h-40 w-8 flex flex-wrap flex-col justify-around z-50'>
                <div className="headContainer-pointer w-3 h-3 hover:w-4 hover:h-4 bg-primary rounded-full" onClick={() => scrollHandler('headContainer')}>
                    <div className="w-3 h-3 hover:w-4 hover:h-4 bg-white rounded-full border-2 hover:animate-ping border-gray hover:cursor-pointer" />
                </div>
                <div className="descriptionContainer-pointer w-3 h-3 hover:w-4 hover:h-4 bg-primary rounded-full" onClick={() => scrollHandler('descriptionContainer')}>
                    <div className="w-3 h-3 hover:w-4 hover:h-4 bg-white rounded-full border-2 hover:animate-ping border-gray hover:cursor-pointer" />
                </div>
                <div className="offersContainer-pointer w-3 h-3 hover:w-4 hover:h-4 bg-primary rounded-full" onClick={() => scrollHandler('offersContainer')}>
                    <div className="w-3 h-3 hover:w-4 hover:h-4 bg-white rounded-full border-2 hover:animate-ping border-gray hover:cursor-pointer" />
                </div>
                <div className="featuresContainer-pointer w-3 h-3 hover:w-4 hover:h-4 bg-primary rounded-full" onClick={() => scrollHandler('featuresContainer')}>
                    <div className="w-3 h-3 hover:w-4 hover:h-4 bg-white rounded-full border-2 hover:animate-ping border-gray hover:cursor-pointer" />
                </div>
                {/* <div className="videoContainer-pointer w-3 h-3 hover:w-4 hover:h-4 bg-primary rounded-full" onClick={() => scrollHandler('videoContainer')}>
                    <div className="w-3 h-3 hover:w-4 hover:h-4 bg-white rounded-full border-2 hover:animate-ping border-gray hover:cursor-pointer" />
                </div> */}
                <div className="subscriptionPlansContainer-pointer w-3 h-3 hover:w-4 hover:h-4 bg-primary rounded-full" onClick={() => scrollHandler('subscriptionPlansContainer')}>
                    <div className="w-3 h-3 hover:w-4 hover:h-4 bg-white rounded-full border-2 hover:animate-ping border-gray hover:cursor-pointer" />
                </div>
                <div className="earlyAccessContainer-pointer w-3 h-3 hover:w-4 hover:h-4 bg-primary rounded-full" onClick={() => scrollHandler('earlyAccessContainer')}>
                    <div className="w-3 h-3 hover:w-4 hover:h-4 bg-white rounded-full border-2 hover:animate-ping border-gray hover:cursor-pointer" />
                </div>
                {/* <div className="w-3 h-3 hover:w-4 hover:h-4 bg-primary rounded-full" onClick={() => scrollHandler('userCountContainer')}>
                    <div className="w-3 h-3 hover:w-4 hover:h-4 bg-white rounded-full border-2 hover:animate-ping border-gray hover:cursor-pointer" />
                </div> */}
                {/* <div className="footContainer-pointer w-3 h-3 hover:w-4 hover:h-4 bg-primary rounded-full" onClick={() => scrollHandler('footContainer')}>
                    <div className="w-3 h-3 hover:w-4 hover:h-4 bg-white rounded-full border-2 hover:animate-ping border-gray hover:cursor-pointer" />
                </div> */}
            </div>
        </div>
    )
}

export default DotScroller;
