import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'
// import {  } from '@fortawesome/free-solid-svg-icons'
import Banner from "../../components/Banner";
import KoolioLogo from '../../assets/images/koolio-icon.png'

const FootContainer = () => {
    return (
        <div className="footContainer">
            <Banner />
            <div className="flex flex-row justify-around items-center text-gray bg-black px-3">
                <div className='flex flex-col justify-start'>
                    <h3>Email Us : info@koolio.ai</h3>
                    <div className='flex flex-row justify-start sm:justify-between text-white mt-2 z-50 gap-1 sm:gap-2'>
                        <div className='hidden sm:flex bg-primary w-10 h-10 items-center justify-center rounded-full'>
                            <a href='https://instagram.com/koolio.ai?igshid=YmMyMTA2M2Y=' target="_blank"  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="xl" />
                            </a>
                        </div>
                        <div className='sm:hidden bg-primary w-6 h-6 flex items-center justify-center rounded-full'>
                            <a href='https://instagram.com/koolio.ai?igshid=YmMyMTA2M2Y=' target="_blank"  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="sm" />
                            </a>
                        </div>
                        <div className='hidden sm:flex bg-primary w-10 h-10  items-center justify-center rounded-full'>
                            <a href='https://youtube.com/channel/UCFO-zeRBQpoKecuOYyG4Mcw' target="_blank"  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutube} size="xl" />
                            </a>
                        </div>
                        <div className='sm:hidden bg-primary w-6 h-6 flex items-center justify-center rounded-full'>
                            <a href='https://youtube.com/channel/UCFO-zeRBQpoKecuOYyG4Mcw' target="_blank"  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutube} size="sm" />
                            </a>
                        </div>
                        <div className='hidden sm:flex bg-primary w-10 h-10 items-center justify-center rounded-full'>
                            <a href='https://twitter.com/koolioai' target="_blank"  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} size="xl" />
                            </a>
                        </div>
                        <div className='sm:hidden bg-primary w-6 h-6 flex items-center justify-center rounded-full'>
                            <a href='https://twitter.com/koolioai' target="_blank"  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} size="sm" />
                            </a>
                        </div>
                        <div className='hidden sm:flex bg-primary w-10 h-10 items-center justify-center rounded-full'>
                            <a href='https://www.facebook.com/Koolioai-105970905569878' target="_blank"  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebookF} size="xl" />
                            </a>
                        </div>
                        <div className='sm:hidden bg-primary w-6 h-6 flex items-center justify-center rounded-full'>
                            <a href='https://www.facebook.com/Koolioai-105970905569878' target="_blank"  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebookF} size="sm" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='w-32 sm:w-40 h-24 overflow-hidden mt-2'>
                    <img src={KoolioLogo} alt='koolio.ai' />
                </div>
                <div className='md:mt-10 text-left'>
                    <h3>2022 &#169; All rights reserved</h3>
                </div>
            </div>
        </div>
    )
}
const FootDesktopView = () =>{
    
}
const FootMobileView = () =>{

}

export default FootContainer;
