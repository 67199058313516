import { useEffect } from "react";

import HeadContainer from "../../containers/HeadContainer";
import DescriptionContainer from "../../containers/DescriptionContainer";
import OffersContainer from "../../containers/OffersContainer";
import FootContainer from "../../containers/FootContainer";
import FeaturesContainer from "../../containers/FeaturesContainer";
import EarlyAccessContainer from "../../containers/EarlyAccessContainer";
import UserCountContainer from "../../containers/UserCountContainer";
import VideoContainer from "../../containers/VideoContainer";
import SubscriptionPlansContainer from "../../containers/SubscriptionPlansContainer";

const Home = () => {

  useEffect(() => {
    document.title = "koolio.ai - What's your story?"
  }, [])


  // useEffect(() => {
  //   setRefContextArr([HeadRef, DescRef, OfferRef, FeatRef, VideoRef, EarlyAccRef, UserCountRef, FootRef])
  // }, [HeadRef, DescRef, OfferRef, FeatRef, VideoRef, EarlyAccRef, UserCountRef, FootRef])


  // console.log("context: ", refContextArr)
  // refContextArr[0].current.scrollIntoView({ behavior: 'smooth' })


  // const scrollTo = (ref) => {
  //   if (ref && ref.current /* + other conditions */) {
  //     ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  //   }
  // }

  return (
    <div className="pageContainer">
      <div className='headContainer'>
        <HeadContainer />
      </div>
      <div className="descriptionContainer">
        <DescriptionContainer />
      </div>
      <div className="offersContainer">
        <OffersContainer />
      </div>
      <div className="featuresContainer ">
        <FeaturesContainer />
        <VideoContainer />
      </div>
      <div className="subscriptionPlansContainer ">
        <SubscriptionPlansContainer/>
      </div>
      <div className="earlyAccessContainer">
        <EarlyAccessContainer />
        {/* <UserCountContainer /> */}
        <FootContainer />
      </div>
    </div>
  )
}

export default Home;
