import FadeInLeftBox from "../../components/UI/FadeInLeftBox";
import OfferCard from "../../components/OfferCard";
import KoolioHeadphone from '../../assets/images/koolio-headphone.webp';
import KoolioGlobe from '../../assets/images/koolio-globe.webp';
import KoolioBulb from '../../assets/images/bulb.webp';

const OffersContainer = () => {
    return (
        <div className="w-full mt-32">
            <div className="header flex flex-col text-center justify-center sm:flex-row">
                <h3 className="text-3xl md:text-lg lg:text-5xl">CREATE WITHOUT&ensp;</h3>
                <h3 className="text-primary text-3xl md:text-lg lg:text-5xl font-bold">LIMITATIONS.</h3>
            </div>
            <div className="header flex flex-col md:mt-4 text-center sm:flex-row justify-center sm:mt-0">
                <h3 className="text-gray text-2xl md:text-lg lg:text-xl">PROFESSIONAL FEATURES IN AN EASY-TO-USE PACKAGE.</h3>
            </div>
            <FadeInLeftBox xOffset={-44} delay={0} duration={1} delayOrder={2}>
                <div className="cards flex mt-16 items-center space-y-8 flex-col sm:flex-row justify-around sm:space-y-0">
                    <OfferCard imgSrc={KoolioBulb} header="INTUITIVE" body="Simple and visual interface designed for all skill levels." />
                    <OfferCard imgSrc={KoolioGlobe} header="WEB-BASED" body="No proprietary software or pro-level devices. Share and collaborate" />
                    <OfferCard imgSrc={KoolioHeadphone} header="STUDIO QUALITY" body="Built in tools for embellishing and enhancing your audio content" />
                </div>
            </FadeInLeftBox>
        </div>
    )
}

export default OffersContainer;
