import React, { useEffect, useState } from 'react';
import PlansShow from './PlansShow';


const SubscriptionPlansContainer = () => {
    const [plans, setPlans]= useState([])
    

    useEffect(()=>{
        fetch("plans.json")
        .then(response =>response.json())
        .then(data => {
            setPlans(data)
        })
        .catch(err=>console.log(err))
    },[])


    const [isRedirectSubscription, setIsRedirectSubscription] = useState(false);

    const redirectSubscription = (event, id, promoCode) => {
        if(!isRedirectSubscription) {
            console.log(event.target,id)
            const allButtons = document.getElementsByClassName("half-modals-action-button")
            for(let i = 0; i < allButtons.length; i++)
            {
                allButtons.item(i).style.opacity = '0.5'
                allButtons.item(i).style.pointerEvents = 'none'
            }
            event.target.style.backgroundColor = "#E2522B"
            event.target.style.opacity = "1"
            event.target.style.color = "#ffffff"
            
            setIsRedirectSubscription(true)
            window.location.href = id? `http://app.koolio.ai/index.html?subscription=${id}&promo_code=${promoCode}`: `http://app.koolio.ai/index.html`
        }
    }
    
    return (
        <div className="w-full mt-32">
            <div className="header text-center">
                <h3 className="text-3xl md:text-4xl lg:text-5xl">EDIT LIKE A<span className='text-primary text-3xl md:text-4xl lg:text-5xl font-bold'> PRO.</span></h3>
                
            </div>
            
            <div className="header text-center mt-4">
                <h3 className="text-gray text-xl md:text-lg lg:text-xl">SUBSCRIBE TO YOUR DESIRED PLAN</h3>
            </div>
            {
               !plans?.length?<div className='flex justify-center'><button type="button" id='spinner' className="bg-black" disabled>
               <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                
               </svg>
               loading plans...
           </button> </div> :<PlansShow plans={plans} redirectSubscription={redirectSubscription}></PlansShow>
            }
            
        </div>
    );
};

export default SubscriptionPlansContainer;