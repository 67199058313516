const MusicSVG = (props) => {
    return (
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 0 439.23 480" xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 439.23 480" height="30" data-type="shape" role="presentation" aria-hidden="true" aria-labelledby="svgcid--kpbu4uilwo5b"><title id="svgcid--kpbu4uilwo5b"></title>
            <g fill={props.fill ? props.fill : "white"}>
                <path d="M256.031 160a8.001 8.001 0 0 0-10.191-7.695l-112 32a8.003 8.003 0 0 0-5.81 7.695v108.45a31.654 31.654 0 0 0-16-4.45c-17.672 0-32 14.328-32 32s14.328 32 32 32c17.676 0 32-14.328 32-32v-97.96l96-27.43v65.84a31.654 31.654 0 0 0-16-4.45c-17.672 0-32 14.328-32 32s14.328 32 32 32c17.676 0 32-14.328 32-32zm-144 184c-8.836 0-16-7.164-16-16s7.164-16 16-16 16 7.164 16 16-7.164 16-16 16zm32-130.61v-15.359l96-27.422v15.36zm80 98.61c-8.836 0-16-7.164-16-16s7.164-16 16-16 16 7.164 16 16-7.164 16-16 16zm0 0"></path>
                <path d="M352.031 288.406V8a8 8 0 0 0-8-8h-240c-.168 0-.32.09-.496.098a7.794 7.794 0 0 0-2.07.414c-.258.09-.496.152-.746.265a7.947 7.947 0 0 0-2.344 1.598l-96 96a7.947 7.947 0 0 0-1.598 2.344c-.113.242-.175.496-.265.746a7.794 7.794 0 0 0-.414 2.07c0 .176-.098.328-.098.496V472a8 8 0 0 0 8 8h336.031c51.149-.43 92.977-40.887 95.114-91.992 2.132-51.106-36.18-94.91-87.114-99.602zm-256-261.094V96H27.344zM291.016 464H16.03V112h88a8 8 0 0 0 8-8V16h224v272.328c-.32 0-.633.098-.96.129-1.337.117-2.645.344-4 .52-1.79.238-3.575.48-5.337.8-1.343.262-2.656.598-4 .918-1.68.403-3.359.801-5.007 1.29-1.329.39-2.622.855-3.926 1.304-1.602.55-3.2 1.102-4.73 1.734-1.286.52-2.544 1.098-3.805 1.672a96.623 96.623 0 0 0-4.442 2.137c-1.23.648-2.398 1.336-3.648 2.031a86.755 86.755 0 0 0-4.16 2.528 99.916 99.916 0 0 0-3.457 2.351 106.157 106.157 0 0 0-3.871 2.899 72.975 72.975 0 0 0-3.2 2.656 128.719 128.719 0 0 0-3.558 3.2 88.516 88.516 0 0 0-2.985 2.944 100.254 100.254 0 0 0-3.242 3.567c-.91 1.047-1.824 2.097-2.695 3.199-1.008 1.258-1.961 2.57-2.903 3.883-.8 1.125-1.601 2.246-2.402 3.406-.894 1.367-1.719 2.777-2.55 4.192-.696 1.19-1.41 2.402-2.048 3.593-.8 1.473-1.472 2.985-2.175 4.504-.578 1.23-1.176 2.453-1.7 3.719-.644 1.601-1.207 3.2-1.773 4.855-.441 1.258-.922 2.489-1.312 3.77-.528 1.742-.938 3.535-1.368 5.32-.297 1.223-.648 2.399-.898 3.656-.399 2.024-.664 4.086-.934 6.153-.144 1.047-.351 2.078-.457 3.14a90.797 90.797 0 0 0 .23 20.801c.137 1.145.345 2.274.52 3.41a93.86 93.86 0 0 0 2.41 11.23 102.87 102.87 0 0 0 2.399 7.376c.367.992.687 1.992 1.09 2.969a97.004 97.004 0 0 0 4.558 9.671c.2.36.434.688.633 1.04a95.615 95.615 0 0 0 5.04 8c.655.937 1.359 1.832 2.046 2.746a97.595 97.595 0 0 0 4.656 5.71c.801.915 1.602 1.81 2.403 2.688 1.793 1.883 3.664 3.664 5.597 5.402.723.637 1.403 1.32 2.145 1.942a98.85 98.85 0 0 0 8.457 6.402c.176.078.258.152.344.215zm53.015 0c-44.183 0-80-35.816-80-80s35.817-80 80-80 80 35.816 80 80c-.047 44.164-35.836 79.953-80 80zm0 0"></path>
                <path d="M349.695 330.352a7.948 7.948 0 0 0-2.605-1.735 8.006 8.006 0 0 0-6.113 0 7.923 7.923 0 0 0-2.61 1.735l-33.926 33.93a8 8 0 0 0 11.313 11.312l20.277-20.282V432a8 8 0 0 0 16 0v-76.688l20.281 20.282a8 8 0 0 0 11.313-11.313zm0 0"></path>
            </g>
        </svg>
    )
}

export default MusicSVG;
