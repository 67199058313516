import HexaSVG from "../../components/HexaSVG";
import ExportSVG from "../../components/SVGComponents/ExportSVG";
import MusicSVG from "../../components/SVGComponents/MusicSVG";
import SpeakerSVG from "../../components/SVGComponents/SpeakerSVG";
import TowerSpeakerSVG from "../../components/SVGComponents/TowerSpeakerSVG";
import WaveSVG from "../../components/SVGComponents/WaveSVG";
import FadeInUpBox from "../../components/UI/FadeInUpBox";

const FeaturesContainer = () => {

    return(
        <div className="w-full mt-44">
            <div className="header flex flex-row sm:flex-row justify-center">
                <h3 className="text-3xl md:text-lg lg:text-5xl">HOW DOES IT&ensp;</h3>
                <h3 className="text-primary text-3xl md:text-lg lg:text-5xl font-bold">WORK?</h3>
            </div>
            <div className="header flex flex-col text-center sm:flex-row justify-center mt-4">
                <h3 className="text-gray text-xl md:text-lg lg:text-xl">EASY STEP BY STEP PROCESS</h3>
            </div>
            <div className=" mt-24 w-full flex flex-col items-center">
                <FadeInUpBox yOffset={54} delay={0} duration={1} delayOrder={2}>
                    <div className="w-full md:w-screen flex flex-wrap flex-col justify-around md:flex-row">
                        <HexaSVG id="01" desc="RECORD OR UPLOAD AUDIO" fill={document.getElementsByTagName("html")[0].classList.contains("dark") ? "black" : "white"} >
                            <MusicSVG fill="#E2522B" />
                        </HexaSVG>
                        <HexaSVG id="02" desc="TRANSCRIBE EACH SPEAKER" fill={document.getElementsByTagName("html")[0].classList.contains("dark") ? "black" : "white"} >
                            <TowerSpeakerSVG fill="#E2522B" />
                        </HexaSVG>
                        <HexaSVG id="03" desc="COLLABORATE CLEAN UP AUDIO" fill={document.getElementsByTagName("html")[0].classList.contains("dark") ? "black" : "white"}>
                            <WaveSVG fill="#E2522B" />
                        </HexaSVG>
                    </div>
                </FadeInUpBox>
                <FadeInUpBox yOffset={54} delay={0.25} duration={1} delayOrder={2}>
                    <div className="flex flex-wrap flex-col w-full md:w-screen justify-evenly md:flex-row">
                        <HexaSVG id="04" desc='ADD CONTEXT-BASED SFX & MUSIC'>
                            <SpeakerSVG fill="#E2522B" />
                        </HexaSVG>
                        <HexaSVG id="05" desc="EXPORT HIGH QUALITY OUTPUT & SHARE">
                            <ExportSVG fill="#E2522B" />
                        </HexaSVG>
                    </div>
                </FadeInUpBox>
            </div>
        </div>
    )
}

export default FeaturesContainer;
