import {useState, useEffect} from 'react';
import Home from './pages/Home';
import './App.css';
import { UserCountContext } from './utils/contexts';
// import ThemeToggler from './components/ThemeToggler';
import DotScroller from './components/DotScroller';
import useWindowDimensions from './hooks/useWindowDimensions';

function App() {
  
  const [userCount, setUserCount] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    fetch(`https://api.koolio.ai/get-invite-count?status=approved`)
    .then(res => res.json())
    .then(data => {
      setUserCount(data.count)
    })
  }, [])
  return (
    <div className='text-black dark:bg-black dark:text-white'> 
      {/* <ThemeToggler /> */}
      {width > 640 && <DotScroller />}
      <UserCountContext.Provider value={{userCount, setUserCount}}>
        <Home />
      </UserCountContext.Provider>
    </div>
  );
}

export default App;
