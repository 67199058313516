import { useState } from "react";
import FadeInUpBox from "../../components/UI/FadeInUpBox";

const EarlyAccessContainer = () => {

    const [isRedirect, setIsRedirect] = useState(false);

    const redirect = () => {
        if(!isRedirect) {
            setIsRedirect(true)
            fetch('https://api.koolio.ai/web-redirect-count', {
                method: 'POST',
                body: JSON.stringify({
                    os: window.navigator.appVersion,
                    timezone: new Date().toString(),
                    created_at:  new Date().toISOString()
                })
            })
            .then(() => {
                window.location.href = 'https://app.koolio.ai/'
            })
        }
    }

    return(
        <div className="">
            <FadeInUpBox yOffset={54} delay={0} duration={1} delayOrder={2}>    
                <div className="w-full h-60 mt-24 sm:mt-40">
                    <div className="header flex flex-col text-center sm:flex-row justify-center space-y-4 sm:space-y-0 text-3xl md:text-lg lg:text-5xl">
                        <h3 className="text-3xl md:text-lg lg:text-5xl">&ensp;ENTER YOUR&ensp;</h3>
                        <div onClick={redirect} className="z-10">
                            <h3 className="text-primary underline cursor-pointer text-3xl md:text-lg lg:text-5xl font-bold">
                                KOOLIOSPACE
                            </h3>
                        </div>
                    </div>
                    {/*<div className="header flex flex-col text-center mt-8 sm:flex-row justify-center space-y-4 sm:space-y-0">
                        <div onClick={redirect} className="z-10">
                            <h3 className="text-primary underline cursor-pointer text-3xl md:text-lg lg:text-5xl font-bold">
                                SIGN UP
                            </h3>
                        </div>
                        <h3 className="text-gray text-3xl md:text-lg lg:text-5xl font-bold space-y-4 sm:space-y-0">
                            &ensp;FOR EARLY ACCESS
                        </h3>
                    </div>*/}
                </div>
            </FadeInUpBox>
        </div>
    )
}

export default EarlyAccessContainer;
