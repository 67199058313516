import React from "react";
import { motion } from "framer-motion";

const arrowAnimate = () => {
    
    const variants = {
        show: {
            opacity: [1, 1, 0, 0]
        }
    };
    let arrowComponents = [];

    for(let i=1.5; i<=2; i=i+0.25){
        arrowComponents.push(
            <motion.div
            key={i}
            animate="show"
            variants={variants}
            transition={{repeat: Infinity, duration: 2, delay: i}}
            className='w-3 h-3 border-r-2 border-b-2 rotate-45 light:border-b-blue-50 dark:border-r-blue-50 dark:border-b-blue-50'></motion.div>
        )
    }
    return arrowComponents;
}

const MouseAnime = () => {

    return (
        <div className='arrow-animate flex flex-col mt-2'>
            {arrowAnimate()}
        </div>
    ) 
}

export default MouseAnime;
